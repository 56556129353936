<template>
  <v-dialog v-model="dialog" :persistent="true" max-width="460px">
    <v-form ref="formNotaFiscal" v-model="valido" lazy-validation style="width: 100%;">
      <v-card>
        <div>
          <v-card-title>
              <span>{{ $tc('label.aprovar', 1)}}</span>
          </v-card-title>
          <v-row>
            <v-col cols="12">
              <div style="max-width: 450px;">
                <dropzone-form
                  ref="dropZoneForm"
                  class="md-hide-medium"
                  :id="uploadFileDropzoneId"
                  :maxFileSizeInMB="50"
                  :url="uriUpload"
                  :auto-process-queue="false"
                  :show-button-send="false"
                  :custom-validate="validarForm"
                  :accepted-file-types="'.pdf'"
                  @UPLOADFILE_FILE_ADDED="preencherDescricaoComNomeArquivo"
                  @UPLOADFILE_SENDING="enviandoArquivo"
                  @UPLOADFILE_SUCCESS="salvar"
                ></dropzone-form>
              </div>
            </v-col>
            <v-col cols="12">
              <div>
                <v-text-field
                  v-model="descricaoArquivo"
                  :rules="[rules.required]"
                  :counter="150"
                  maxlength="150"
                  :label="`${$t('label.descricao')}*`"
                  class="ml-3 mr-3"
                  required
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="12">
              <div>
                <v-text-field
                  v-model="numeroNf"
                  :rules="[rules.required]"
                  maxlength="150"
                  type="number"
                  :label="`${$t('label.numero_nf')}*`"
                  class="ml-3 mr-3"
                  required
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="close()">
              {{$t('label.cancelar') }}
            </v-btn>
            <v-btn
              dark
              color="primary"
              @click="enviarArquivo()">
              {{$t('label.salvar') }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirm from '@/produto/shared-components/vuetify/dialog/Confirm';
import basePath from '../../../common/functions/api-resource';
import DropzoneForm from '../../../shared-components/upload/DropzoneForm';

export default {
  name: 'PlanejamentoAcaoVerbaVariavelModalNotaFiscal',
  components: {
    DropzoneForm,
    Confirm,
  },
  props: {
    idAcao: Number,
    executaBuscaAcoes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      descricaoArquivo: null,
      exibeArquivos: false,
      dialog: false,
      valido: false,
      numeroNf: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      planejamentoAcaoCadastroResource: this.$api.planejamentoAcaoCadastro(this.$resource),
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    uriUpload() {
      const urlUpload = basePath('job', 'arquivo/acao');
      return `${urlUpload}/${this.idAcao}/upload`;
    },
    hasRole() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ACAO_CRUD').length;
    },
    uploadFileDropzoneId() {
      return `myVueDropzone${(Math.random() * Math.random() * 100).toString().replace('.', '')}`;
    },
  },
  methods: {
    enviandoArquivo(data, xhr, formData) {
      formData.append('descricao', encodeURI(this.descricaoArquivo));
      formData.append('numeroNF', encodeURI(this.numeroNf));
    },
    preencherDescricaoComNomeArquivo(file) {
      this.descricaoArquivo = file.name;
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.resetForm();
    },
    cancelar() {
      this.close();
      this.$emit('cancelar');
    },
    validarForm() {
      return this.$refs.formNotaFiscal.validate();
    },
    enviarArquivo() {
      this.$refs.dropZoneForm.submitForm();
    },
    salvar() {
      this.$emit('APROVAR_NOTA_FISCAL', this.numeroNf);
      this.close();
    },
    resetForm() {
      this.descricaoArquivo = null;
      this.numeroNf = null;
      this.$refs.dropZoneForm.resetForm();
    },
  },
};
</script>
<style scoped>
.anexo-desabilitado {
padding: 12px 0 0 12px;
font-size: 16px !important;
color: red;
}
.painel-anexo .container.mx-0 {
max-width: 97% !important;
}
</style>
